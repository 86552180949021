import st from "./Main.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

import swiper1 from "./images/swiper/swiper1.jpg";
import swiper2 from "./images/swiper/swiper2.jpg";
import swiper3 from "./images/swiper/swiper3.jpg";
import swiper4 from "./images/swiper/swiper4.jpg";
// import swiper5 from "./images/swiper/swiper5.png";
// import swiper6 from "./images/swiper/swiper6.png";
// import swiper7 from "./images/swiper/swiper7.png";
// import swiper8 from "./images/swiper/swiper8.png";

import arrowRight from "./images/arrowright.png";
import share from "./images/share.png";
import upload from "./images/upload.png";
import reduce from "./images/reduce.png";
import deleteIcon from "./images/delete.png";
import info from "../Top/images/info.png";
import mobile from "./images/mobile.png";
import pc from "./images/pc.png";
import star from "./images/star.svg";
import halfStar from "./images/star-half.png";
import more from "./images/more.png";
import flag from "./images/flag.png";
import country from "./images/country.png";
import down from "./images/down.png";
import starGray from "./images/star-gray.png";
import user1 from "./images/users/user1.png";
import user2 from "./images/users/user2.png";
import user3 from "./images/users/user3.png";
import game1 from "./images/games/game1.png";
import game2 from "./images/games/game2.png";
import game3 from "./images/games/game3.png";
import game4 from "./images/games/game4.png";
import game5 from "./images/games/game5.png";
import game6 from "./images/games/game6.png";

const Main = (props) => {
    const { downLoadApp } = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    const swiperList = [swiper1, swiper2, swiper3, swiper4];



    const rateList = [
        { num: 5, width: "90%" },
        { num: 4, width: "20%" },
        { num: 3, width: "10%" },
        { num: 2, width: "0%" },
        { num: 1, width: "5%" },
    ];

    const footerList = [
        {
            title: "Google Play",
            children: ["Play Pass", "Play Points", "Gift cards", "Redeem", "Refund policy"],
        },
        {
            title: "Kids & family",
            children: ["Parent Guide", "Family sharing"],
        },
    ];

    const evaluateList = [
        {
            avatar: user1,
            userName: "Lisa G",
            time: "November 15, 2023",
            content: "Update..plan on spending bank if you want to play. Wins are not enough to sustain playing for a decent amount of time. I agree with the other reviews stating that the winnings become less and less. The more coins you purchase, the more quickly you lose them. After purchasing the piggy bank, the price skyrocketed for any additional purchase. The more dedicated you are to playing, the higher the prices go. There are plenty of other slot apps available that have a much higher win/lose ratio.",
            userfulDesc: "2,008 people found this review helpful",
        },
        {
            avatar: user2,
            userName: "Karen S",
            time: "October 15, 2023",
            content:
                "Great choice of popular games that are also in the casinos live, and lots of fun to play! Watching ads alone can sustain play, but can be agonizingly slow at times. Be prepared to make purchases or have lots of patience. Sometimes, you have to watch a 30 second to a minute long ad in between a few hands of play, and that can go on for several hands. On some games, you can't complete the bonus requirements unless you buy money to play. STILL, my favorite of all the casino apps to play!",
            userfulDesc: "3,394 people found this review helpful",
        },
        {
            avatar: user3,
            userName: "Donita C",
            time: "September 14, 2022",
            content: "I really enjoy this game. It has a variety of different slots to play. I can earn extra coins as I play. Not many ads to watch. The amount of coins when buying is lower than other games, but due to the quality of this game, they are worth it in my opinion. I have downloaded and then deleted other apps after playing them.",
            userfulDesc: "1,110 people found this review helpful",
        },
    ];

    const gameList1 = [
        {
            img: game1,
            title: "Lightning Link Casino Slots",
            desc: "Product Madness",
            star: "4.3",
        },
        {
            img: game2,
            title: "Quick Hit Casino Slot Games",
            desc: "SciPlay",
            star: "4.7",
        },
        {
            img: game3,
            title: "Lucky Time Slots Casino Games",
            desc: "DGN Games",
            star: "4.8",
        },
        {
            img: game4,
            title: "Cash Tornado™ Slots - Casino",
            desc: "Zeroo Gravity Games",
            star: "4.6",
        },
        {
            img: game5,
            title: "Gold Fish Casino Slot Games",
            desc: "SciPlay",
            star: "4.8",
        },
    
    ];


    return (
        <div className={st.container}>
            <div className={st.content}>
                <div className={st.contentLeft}>
                    <div className={st.swiperContainer}>
                        <Swiper
                            slidesPerView="auto"
                            className={st.swiperContent}
                            navigation={{ nextEl: ".navigation-next", prevEl: ".navigation-prev" }}
                            spaceBetween={12}
                            modules={[Navigation]}>
                            {swiperList.map((v, key) => {
                                return (
                                    <SwiperSlide key={key} style={{ width: "auto" }}>
                                        <img src={v} alt="" className={st.swiperImg} onClick={clickHandler} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div className={st.contentGameInfo}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Acerca del juego
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentGameInfoText}>
                        Bienvenido a Playing.io, ¡donde tu experiencia de juego se convierte en una emoción de dinero real! Domina la estrategia, confía en la suerte, desafía a jugadores de todo el mundo y compite por un jackpot lucrativo. En este juego emocionante, descubre una jugabilidad innovadora, momentos emocionantes y la oportunidad de ganar recompensas sustanciales en dinero real.
                            <br />
                            <br />
                            Batallas en tiempo real: Enfréntate en batallas con jugadores de dinero real de todo el mundo, sumergiéndote en la auténtica atmósfera del casino.
                            <br />
                            Jugabilidad innovadora: Playing.io va más allá de los juegos de rummy tradicionales. Presentando reglas y desafíos innovadores, te ofrecemos una experiencia de juego única.
                            <br />
                            Gran bote de jackpot: Participa en competiciones intensas, gana de un gran bote de jackpot y emerge como el gran ganador.
                            <br />
                            Desafíos diversos: Diversas salas y niveles de apuestas satisfacen a todo tipo de jugadores, desde principiantes hasta expertos. Todos pueden encontrar un desafío adecuado.
                            <br />
                            Estamos comprometidos a proporcionar un entorno de juego seguro y justo. Playing.io utiliza tecnología avanzada para garantizar la seguridad de la información personal de los jugadores y las transacciones, garantizando la equidad y transparencia del juego.
                            <br />
                            Emprende tu aventura:
                            <br />
                            <br />
                            ¡Descarga Playing.io y emprende tu aventura de juego! Compite con jugadores de todo el mundo, muestra tus habilidades y gana recompensas en dinero real. Es emocionante, es excitante: ¡Playing.io está esperando que te unas a la acción!
                        </div>
                    </div>

                    <div className={st.contentSafe}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Seguridad de los datos
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentSafeText}>
                        La seguridad comienza con entender cómo los desarrolladores recopilan y comparten tus datos. Las prácticas de privacidad y seguridad de datos pueden variar según tu uso, región y edad. El desarrollador proporcionó esta información y puede actualizarla con el tiempo.
                        </div>
                        <div className={st.contentSafeTable}>
                            <div className={st.contentSafeTableItem}>
                                <img src={share} alt="" />
                                <div>
                                Puede que esta aplicación comparta estos tipos de datos con terceros
                                    <br />
                                    Actividad en la aplicación e IDs de dispositivo o de otro tipo
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={upload} alt="" />
                                <div>
                                Esta aplicación puede recoger estos tipos de datos
                                    <br />
                                    Ubicación, Información personal y otros 4
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={reduce} alt="" />
                                <div>Los datos se cifran en tránsito</div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={deleteIcon} alt="" />
                                <div>Puedes solicitar que se eliminen los datos</div>
                            </div>
                            <div className={st.contentSafeTableDetail} onClick={clickHandler}>
                            Ver detalles
                            </div>
                        </div>
                    </div>
                    <div className={st.contentRate}>
                        <div className={st.contentRateTitle}>
                            <div className={st.infoTitle} onClick={clickHandler}>
                            Valoraciones y reseñas
                                <img src={arrowRight} alt="" />
                            </div>
                            <div className={st.contentRateTitleR} onClick={clickHandler}>
                            Valoraciones y reseñas verificadas
                                <img src={info} alt="" />
                            </div>
                        </div>
                        <div className={st.contentRateDevice}>
                            <div className={st.contentRateDeviceItem} onClick={clickHandler}>
                                <img src={mobile} alt="" />
                                Teléfono
                            </div>
                            <div className={st.contentRateDeviceItemPc} onClick={clickHandler}>
                                <img src={pc} alt="" />
                                Tablet
                            </div>
                        </div>
                        <div className={st.contentRateDetail}>
                            <div className={st.contentRateDetailL}>
                                <div className={st.contentRateDetailLNum}>4.9</div>
                                <div className={st.contentRateDetailLStar}>
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={halfStar} alt="" />
                                </div>
                                <div>577 mil reseñas</div>
                            </div>
                            <div className={st.contentRateDetailR}>
                                {rateList.map((v, key) => {
                                    return (
                                        <div key={key} className={st.contentRateDetailRItem}>
                                            <div className={st.contentRateDetailRItemNum}>{v.num}</div>
                                            <div className={st.contentRateDetailRItemProgress}>
                                                <div className={st.contentRateDetailRItemProgressInner} style={{ width: v.width }}></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={st.contentRateEvaluate}>
                            {evaluateList.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRateEvaluateItem} onClick={clickHandler}>
                                        <div className={st.contentRateEvaluateItemTitle}>
                                            <img src={v.avatar} alt="" className={st.contentRateEvaluateItemTitleAvatar} />
                                            <div className={st.contentRateEvaluateItemTitleName}>{v.userName}</div>
                                            <img src={more} alt="" className={st.contentRateEvaluateItemTitleMore} />
                                        </div>
                                        <div className={st.contentRateEvaluateItemRate}>
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <div>{v.time}</div>
                                        </div>
                                        <div className={st.contentRateEvaluateItemContent}>{v.content}</div>
                                        <div className={st.contentRateEvaluateItemUserful}>{v.userfulDesc}</div>
                                        <div className={st.contentRateEvaluateItemIsUserful}>
                                            <div className={st.contentRateEvaluateItemIsUserfulText}>¿Te ha parecido útil?</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>Sí</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>No</div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className={st.contentRateEvaluateMore}>Ver todas las reseñas</div>
                        </div>
                    </div>
                    <div className={st.contentNew}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Novedades
                        </div>
                        <div className={st.contentNewContent}>
                            Get our NEW games in our latest update!<br />
                            For the best experience and most FUN we’ve squashed bugs and optimized your game.<br />
                        </div>
                    </div>
                </div>
                <div className={st.contentRight}>
                    <div className={st.infoTitle} onClick={clickHandler}>
                    Asistencia de la aplicación
                        <img src={down} alt="" />
                    </div>
                    <div className={st.contentRightOther}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Juegos similares
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentRightOtherGameList}>
                            {gameList1.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRightGameItem} onClick={clickHandler}>
                                        <img src={v.img} alt="" className={st.contentRightGameItemImg} />
                                        <div className={st.contentRightGameItemImfo}>
                                            <div className={st.contentRightGameItemImfoTitle}>{v.title}</div>
                                            <div className={st.contentRightGameItemImfoDesc}>{v.desc}</div>
                                            <div className={st.contentRightGameItemImfoStar}>
                                                {v.star}
                                                <img src={starGray} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
            </div>
            <div className={st.report} onClick={clickHandler}>
                <img src={flag} alt="" />
                Marcar como inapropiado
            </div>
            <div className={st.footer}>
                <div className={st.divider}></div>
                <div className={st.footerInfo}>
                    {footerList.map((v, key) => {
                        return (
                            <div key={key} className={st.footerInfoItem}>
                                <div className={st.footerInfoItemTitle} onClick={clickHandler}>
                                    {v.title}
                                </div>
                                {v.children.map((v1, key1) => {
                                    return (
                                        <div key={key1} className={st.footerInfoItemText} onClick={clickHandler}>
                                            {v1}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={st.desc} onClick={clickHandler}>
                <div>Términos del Servicio</div>
                <div>Privacidad</div>
                <div>Información sobre Google Play</div>
                <div>Desarrolladores</div>
                <div>Google Store</div>
                <img src={country} alt="" />
                <div>Estados Unidos (Español)</div>
            </div>
            <div style={{ width: "100%", height: "36px" }}></div>
        </div>
    );
};

export default Main;
